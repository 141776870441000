.fade-out {
    -webkit-transition: opacity 3s ease-in-out;
    -moz-transition: opacity 3s ease-in-out;
    -ms-transition: opacity 3s ease-in-out;
    -o-transition: opacity 3s ease-in-out;
}
/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
      background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
      background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
  
    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
  box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  :root {
    --input-padding-x: 1.5rem;
    --input-padding-y: .75rem;
  }
  * {
    box-sizing: border-box;
   }
   *:before,
   *:after {
    box-sizing: border-box;
   }
   html,
   body {
    height: 100%;
    position: relative;
   }
   .main-container {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 40px; /* height of your footer */
   }
   footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999999;
   }
   .products
{
  position: relative;
  height: 0;
  padding-top: 56.25%;
}

.products img
{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.product-image
{
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  -webkit-transition: all 2s ease-out;
  transition: all 2s ease-out;
}
.searchbar{
    margin-bottom: auto;
    margin-top: auto;
    height: 60px;
    background-color: lightgray;
    border-radius: 30px;
    padding: 10px;
    width: 100%;
    }

    .search_input{
    color: #000;
    border: 0;
    outline: 0;
    background: none;
    width: 0;
    line-height: 40px;
    -webkit-transition: width 0.4s linear;
    transition: width 0.4s linear;
    caret-color:red;
    width: 100%;
    margin-left: -50px;
    padding-left: 60px;
    }

    .searchbar:hover > .search_icon{
    background: white;
    color: #e74c3c;
    }

    .search_icon{
    height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color:white;
    }
    #qty_input{
      text-align: center;
      height: 40px;
      font-size: 20px;
    }
    input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
   
  input[type="number"] {
      -moz-appearance: textfield;
  }
  .cat-list > .list-group-item{
    font-size: 14px;
    padding: 5px 2px;
  }
